/*Put this to react component*/

.propertyCardLike {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: rgba(111, 207, 151, 0.5);
  opacity: 0;
  z-index: 5;
  pointer-events: none;

  transition: all 0.2s ease-in-out;
}

.propertyCardDislike {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5);
  opacity: 0;
  z-index: 5;
  pointer-events: none;

  transition: all 0.2s ease-in-out;
}

.property--cards {
  flex-grow: 1;
  /* padding-top: 10%; */
  position: relative;

  height: 550px;
  width: 800px;
  text-align: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.property--card {
  top: 10%;
  display: inline-block;
  /* width: 90vw; */
  /* max-width: 400px; */
  /* height: 70vh; */
  background: #ffffff;
  /* padding-bottom: 2px; */
  /* border-radius: 8px; */
  /* overflow: hidden; */
  position: absolute;
  will-change: transform;
  transition: all 0.2s ease-in-out;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.property--card div,
.property--card img,
.property--card p {
  pointer-events: none;
}

.moving.property--card {
  transition: none;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
