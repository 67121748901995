table.offering { 
	width: 100%; 
	border-collapse: collapse; 
	margin:50px auto;
	}


	table.offering tr { 

	color: orange; 
	font-weight: bold; 
	}

	table.offering th { 
	background: #35C0CA; 
	color: white; 
	font-weight: bold; 
	}

	table.offering td, table.offering th { 
	padding: 10px; 
	border: 1px solid #ccc; 
	text-align: left; 
	font-size: 18px;
	}

	table.offering td:nth-child(1) {
    background: #35C0CA; 
   color: #FFFFFF;
}

@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	table.offering { 
	  	width: 100%; 
	}

	/* Force table to not be like tables anymore */
	table.offering, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	table.offering thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	table.offering tr { border: 1px solid #ccc; }
	
	table.offering td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}

	table.offering td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
		/* Label the data */
		content: attr(data-column);

		color: #000;
		font-weight: bold;
	}

}